import './assets/main.css'
import { createApp } from 'vue'
import App from './App.vue'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import router from './router'
import { firebaseApp } from './firebase'
import { VueFire, VueFireAppCheck, VueFireAuth } from 'vuefire'
import { vMaska } from 'maska/vue'
import * as Sentry from '@sentry/vue'
import { ReCaptchaV3Provider } from 'firebase/app-check'

// Type declaration for debug token
declare global {
  interface Window {
    FIREBASE_APPCHECK_DEBUG_TOKEN?: boolean;
  }
}

// Safer development check
const isDevelopment = import.meta.env.MODE === 'development'

if (isDevelopment) {
  window.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
}

const app = createApp(App)

Sentry.init({
  app,
  dsn: 'https://c78040f8f2d132e6e51e9af3299f8469@o4507448838914048.ingest.us.sentry.io/4507448885248000',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['sachin.local', /^https:\/\/method\.foundation\//],
  replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

app.directive('maska', vMaska)

app.use(VueFire, {
  firebaseApp,
  modules: [
    VueFireAuth(),
    VueFireAppCheck({
      provider: new ReCaptchaV3Provider('6Ldvu3sqAAAAAGKjG0voXX7ZQdTTHHGIuMDIJPs2'),
      isTokenAutoRefreshEnabled: true,
      debug: isDevelopment
    }),
  ],
})

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
app.use(pinia)

app.use(router)

app.mount('#app')
